import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../../templates/GreenwaveHeader/index";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import formatCurrency from '../../../helpers/formatCurrency';
import Footer from '../../templates/GreenwaveFooter/index';

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 180px auto 0 auto;
`;

const PaymentWrapper = styled.div`
  border-radius: 12px;
  padding: 30px 45px 55px 45px;
  height: 750px;
  box-shadow: 0px 10px 20px #00000029;
  background-color: #F7F7F7;
  width: 1200px;
  margin: 0 auto 200px auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1250px) {
    width: 95%;
    margin: 0 auto 200px auto;
  }

  @media (max-width: 850px) {
    height: 1050px;
  }

  @media (max-width: 600px) {
    padding-right: 20px;
  }

  @media (max-width: 500px) {
    padding: 30px 0px 55px 25px;
  }

  @media (max-width: 400px) {
    height: 1600px;
    padding: 30px 20px 35px 20px;
  }
`;

const TextColumn = styled.div`
  text-align: center;
  width: 100%;
  background: white;
  padding: 20px;
  border-radius: 15px;
  margin: 5px;

  @media (max-width: 850px) {
      width: 48%;
  }
  @media (min-width: 401px) and (max-width: 625px) {
      width: 45%;
  }
  @media (max-width: 400px) {
      width: 100%;
  }
`;


const ProposedText = styled.h3`
  font-size: 15px;
  text-transform: uppercase;
  color: #022737;
  font-weight: 900;
  margin-bottom: 0;
`;
const ProposedInformation = styled.p`
  font-size: 25px;
  font-weight: 500;
  color: #022738;
  margin: 5px 0 15px 0px;
`;

const HeaderText = styled.h4`
  text-transform: uppercase;
  font-size: 22px;
  color: #022738;

  .check-icon {
    color: #5A97AB;
    margin-right: 8px;
    font-size: 26px;
  }
`;

const SubHeader = styled.h2`
  font-size: 20px;
  color: #022738;
  padding-left: 10px;
  margin-bottom: 10px;
`;

const PlanConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const PaymentContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 850px) {
    flex-wrap: wrap;
    align-items: center;
  }

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const Questions = styled.p`
  color: #10526E;
  font-size: 17px;
  margin-bottom: 0px;
  position: absolute;
  bottom: 10px;
  width: 60%;


  a {
      color: #0CA14A;
      text-decoration: none;
  }

  @media (max-width: 400px) {
    width: 97%;
    bottom: 165px;
  }
`;

const ConfirmPage = () => {
  const [offerData, setOfferData] = useState('');

  useEffect(() => {
    setOfferData(JSON.parse(sessionStorage.getItem('offerData')))
  }, []);

  return (
    <>
      <Header />
      <Wrapper>
        {offerData !== '' ?
          <PaymentWrapper>
            <PlanConfirmation>
              <HeaderText>
                <FontAwesomeIcon icon={faCheckCircle} alt="Check Sign" className="check-icon" />
                Confirmation
              </HeaderText>

              <SubHeader>Payment Details:</SubHeader>
              <PaymentContainer>
                <TextColumn>
                  <ProposedText>Paid:</ProposedText>
                  <ProposedInformation>${offerData.payment}</ProposedInformation>
                </TextColumn>

                <TextColumn>
                  <ProposedText>Tax:</ProposedText>
                  <ProposedInformation>${offerData.total_tax}</ProposedInformation>
                </TextColumn>

                <TextColumn>
                  <ProposedText>Total:</ProposedText>
                  <ProposedInformation>${offerData.amount_post_tax}</ProposedInformation>
                </TextColumn>
              </PaymentContainer>

              <SubHeader>Offer Details:</SubHeader>
              <PaymentContainer>
                <TextColumn>
                  <ProposedText>Pay:</ProposedText>
                  <ProposedInformation>${offerData.payment}</ProposedInformation>
                </TextColumn>

                <TextColumn>
                  <ProposedText>Duration:</ProposedText>
                  <ProposedInformation>{offerData.months} Months</ProposedInformation>
                </TextColumn>

                <TextColumn>
                  <ProposedText>Total Tax:</ProposedText>
                  <ProposedInformation>${offerData.total_tax}</ProposedInformation>
                </TextColumn>

                <TextColumn>
                  <ProposedText>Post Tax Amount:</ProposedText>
                  <ProposedInformation>${offerData.amount_post_tax}</ProposedInformation>
                </TextColumn>
              </PaymentContainer>

              <Questions>You will receive an email confirmation shortly. For any questions or to cancel your plan please contact Greenwave at <a href="tel:3852471968">(385) 247-1968</a>.</Questions>
              <StaticImage src="../../../resources/images/logos/GreenWave.png" alt="Greenwave Logo" placeholder="none" style={{ width: '100px', position: 'absolute', right: '20px', bottom: '0px' }} />
            </PlanConfirmation>

          </PaymentWrapper>
          : null}
      </Wrapper>

      <Footer />
    </>
  )

}

export default ConfirmPage























